<template>
    <section class="usr_login">
        <el-form class="el_form_override">
            <h3>
                闲牛租后台管理系统
            </h3>
            <el-form-item>
                <el-input prefix-icon="User" placeholder="请输入账号" v-model="username" size="large" autocomplete="off" :autofocus="false" @keyup.enter="handleclick()" clearable />
            </el-form-item>
            <el-form-item>
                <el-input prefix-icon="Lock" :show-password="true" placeholder="请输入密码" v-model="password" size="large" autocomplete="off" @keyup.enter="handleclick()" />
            </el-form-item>
            <el-form-item>
                <div class="login-option">
                    <el-radio-group  v-model="accounttype">
                        <el-radio-button  value="1" label="运维人员" />
                        <el-radio-button  value="3" label="星火账户" />
                        <!-- <el-radio-button style="flex: 1;" value="4" label="业务员" /> -->
                    </el-radio-group>
                    <el-button type="primary" @click="handleclick()">登录</el-button>
                </div>
            </el-form-item>
        </el-form>
    </section>
</template>
<script>
import Cookies from 'js-cookie'
import constant from '@/constant';
export default {
    data() {
        return {
            username: '',
            password: '',
            accounttype: '1',
        }
    },
    created: function () {
        console.log(Cookies);
        let cookies = Cookies.get("token");
        if (cookies) {
            let role = Cookies.get("role");
            console.log(role)
            if (role == 5) {
                this.$router.push('/SparkFire');
            } else if(role == 6){
                this.$router.push({path:'/OverDueOrder'});
            }else {
                let redirectPath = this.$route.query.redirect || "/home";
                this.$router.push(redirectPath);
            }
        }
    },
    methods: {
        handleclick() {
            let acctype = this.accounttype
            this.axios.get(constant.login_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    username: this.username,
                    userpass: this.password,
                    accounttype: this.accounttype,
                }
            }).then((response) => {
                console.log(response.data);
                console.log("name--" + response.data.name);
                console.log("id--" + response.data.id);
                console.log("session--" + response.data.session);
                console.log("role--" + response.data.role);
                if (response.data.message == "NOUSER") {
                    this.$message.error("该用户不存在或已失效");
                    return false;
                } else if (response.data.message == "ERROR") {
                    this.$message.error("请填写用户名和密码");
                    return false;
                } else if (response.data.message == "TEST") {
                    return false;
                } else {
                    this.$message.success("登录成功");
                    Cookies.set('name', response.data.name, { expires: 1 });
                    Cookies.set('token', response.data.token, { expires: 1 });
                    Cookies.set('id', response.data.id, { expires: 1 });
                    Cookies.set('role', response.data.role, { expires: 1 });
                    Cookies.set("session", response.data.session, { expires: 1 });
                    Cookies.set('IsMerchant', response.data.IsMerchant, { expires: 1 });
                    if (response.data.mid)
                        Cookies.set('mid', response.data.mid, { expires: 1 })
                    if (response.data.role == 2 || response.data.role == 4) {
                        Cookies.set('patharr', response.data.patharr, { expires: 1 });
                    }
                    const redirectPath = this.$route.query.redirect || "/home";
                    console.log(acctype, redirectPath)
                    if (acctype == 3) {
                        this.$router.push('/SparkFire');
                    } else if (acctype == 4){
                        this.$router.push({path:'/OverDueOrder'});
                    }else {
                        this.$router.push(redirectPath);
                    }
                }
            })
        },
        setCookie(name, value, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 12 * 60 * 60 * 1000));
            var expires = "expires=" + d.toGMTString();
            document.cookie = name + "=" + value + ";" + expires;
        },
        getCookie(name) {
            let ret, m;
            if (typeof name == "string" && name !== "") {
                if ((m = String(document.cookie).match(new RegExp('(?:^| )' + name + '(?:(?:=([^;]*))|;|$)')))) {
                    ret = m[1] ? decodeURIComponent(m[1]) : ""
                }
            }
            return ret
        },
        DoLogin() {
            var username = getCookie("username");
            if (username) {
                const redirectPath = this.$route.query.redirect || "/home";
                this.$router.push(redirectPath);
            }
            function getCookie(name) {
                let ret, m;
                if (typeof name == "string" && name !== "") {
                    if ((m = String(document.cookie).match(new RegExp('(?:^| )' + name + '(?:(?:=([^;]*))|;|$)')))) {
                        ret = m[1] ? decodeURIComponent(m[1]) : ""
                    }
                }
                return ret
            }
        },
    }
}
</script>
<style >
.usr_login {
    background-color: #2d3a4b;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.el_form_override {
    min-width: 300px;
    padding: 50px;
    background-color: rgb(41, 51, 67);
    border: 1px solid rgb(50, 60, 74);
    border-radius: 5px;
}
.el-radio-button__inner{
    width: 100%;
}
.el_form_override h3 {
    font-size: 26px;
    color: #eee;
    margin-bottom: 40px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.el_form_override h3 .el-icon {
    margin-right: 10px;
}
</style>
